<template>
  <div class="main-box">
    <div class="header">
      <div class="header-content">
        欢迎进入新视采官网
        <p style="float:right">
          <el-divider direction="vertical"></el-divider>
          <el-popover class="tip-box" placement="bottom-end" title width="200" trigger="hover">
            <div slot="reference">
              <a>小程序码</a>
            </div>
            <slot>
              <img width="100%" src="../assets/xcx.jpg" alt />
              <p style="font-size:14px;color:#323232;text-align:center">扫码立即体验[新视采]小程序</p>
            </slot>
          </el-popover>
          <el-divider direction="vertical"></el-divider>
          <el-popover class="tip-box" placement="bottom-end" title width="200" trigger="hover">
            <div slot="reference">
              <a>
                关注微信
                <img src="../assets/top_icon_wechat@2x.png" />
              </a>
            </div>
            <slot>
              <img width="100%" src="../assets/qrcode.png" alt />
              <p style="font-size:14px;color:#323232;text-align:center">扫码立即关注[新视采]</p>
            </slot>
          </el-popover>
        </p>
      </div>
    </div>
    <div class="nav">
      <div class="main">
        <img src="../assets/logo@2x.png" alt />
        <ul class="nav-tab">
          <li @click="activeNavIndex = 0">
            <span :class="activeNavIndex === 0?'active':''" @click="scrollIntoView('div1')">首页</span>
          </li>
          <li @click="activeNavIndex = 1">
            <span :class="activeNavIndex === 1?'active':''" @click="scrollIntoView('div2')">采购方式</span>
          </li>
          <li @click="activeNavIndex = 2">
            <span :class="activeNavIndex === 2?'active':''" @click="scrollIntoView('div3')">核心理念</span>
          </li>
          <li @click="activeNavIndex = 3">
            <span :class="activeNavIndex === 3?'active':''" @click="scrollIntoView('div4')">操作流程</span>
          </li>
          <li @click="activeNavIndex = 4">
            <span :class="activeNavIndex === 4?'active':''" @click="scrollIntoView('div5')">关于我们</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="content">
      <div id="div1" class="carousel">
        <el-carousel :height="imgHeight" :interval="3000" arrow="always">
          <el-carousel-item>
            <img width="100%" src="../assets/banner@2x.png" alt />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="buy" id="div2">
        <p class="title-p1">打破传统、新的采购方式</p>
        <p class="title-p2">高效低价购买您要的商品</p>
        <div class="main">
          <div class="div1">
            <p>
              <img class="img1" src="../assets/index_advantage_icon_one@2x.png" alt />
            </p>
            <p class="p1">
              <img class="img2" src="../assets/introduction_number_one@2x.png" alt />
              客户提交需求
            </p>
            <p class="p2">客户登录小程序填写商品要求</p>
          </div>
          <div class="div2">
            <img src="../assets/arrows@2x.png" alt />
          </div>
          <div class="div1">
            <p>
              <img class="img1" src="../assets/index_advantage_icon_two@2x.png" alt />
            </p>
            <p class="p1">
              <img class="img2" src="../assets/introduction_number_two@2x.png" alt />
              人工验证信息
            </p>
            <p class="p2">人工验证提供的商品信息</p>
          </div>
          <div class="div2">
            <img src="../assets/arrows@2x.png" alt />
          </div>
          <div class="div1">
            <p>
              <img class="img1" src="../assets/index_advantage_icon_three@2x.png" alt />
            </p>
            <p class="p1">
              <img class="img2" src="../assets/introduction_number_three@2x.png" alt />
              匹配供应商
            </p>
            <p class="p2">人工线下寻找低价购买商</p>
          </div>
          <div class="div2">
            <img src="../assets/arrows@2x.png" alt />
          </div>
          <div class="div1">
            <p>
              <img class="img1" src="../assets/index_advantage_icon_four@2x.png" alt />
            </p>
            <p class="p1">
              <img class="img2" src="../assets/introduction_number_four@2x.png" alt />
              完成采购
            </p>
            <p class="p2">采购完成，购买到商品</p>
          </div>
        </div>
      </div>
      <div class="ln" id="div3">
        <!-- <img class="bg" src="../assets/idea_bg_image@2x.png" alt=""> -->
        <div class="main">
          <div class="top-div">
            <div>
              <p>核心·理念</p>
              <p class="little-p">CORE IDEOLOGY</p>
              <p class="d-p">
                <a class="a1"></a>
                <a class="a2"></a>
              </p>
            </div>
          </div>
          <div class="bottom-box">
            <div class="bottom-div">我们恪守明确的道德标准，坚持品格操守无可妥协，诚信正直是我们最根本也是最重要的理念。不论面对任何人，我们均永远保持诚恳以及言行一致。</div>
          </div>
        </div>
        <div class="imgs">
          <div class="item">
            <img src="../assets/page1@2x.png" alt />
            <p>工业机器人</p>
          </div>
          <div class="item">
            <img src="../assets/page2@2x.png" alt />
            <p>润滑元件类</p>
          </div>
          <div class="item">
            <img src="../assets/page3@2x.png" alt />
            <p>液压元件</p>
          </div>
          <div class="item">
            <img src="../assets/page4@2x.png" alt />
            <p>MORE…</p>
          </div>
          <div class="kf">
            <p class="p1">联系我们</p>
            <p class="p2">
              <img src="../assets/footer_icon_iphone@2x.png" alt /> 182-5569-8813
            </p>
            <p class="p3">
              <img src="../assets/qrcode.png" alt />
            </p>
          </div>
        </div>
      </div>
      <div class="lc" id="div4">
        <div class="main">
          <div class="top-div">
            <p>操作·流程</p>
            <p class="little-p">OPERATION PROCEDURE</p>
            <p class="d-p">
              <a class="a1"></a>
              <a class="a2"></a>
            </p>
          </div>
          <img width="100%" src="../assets/flow_icon_image@2x.png" alt />
        </div>
      </div>
      <div class="xj">
        <p class="title-p1">询价流程</p>
        <p class="title-p2">点击可查看大图，左右可滑动</p>
        <div class="main">
          <el-carousel :interval="4000" type="card" height="720px" @change="changeTab">
            <el-carousel-item>
              <img src="../assets/page/page1@2x.png" alt />
              <img class="mask" v-if="activeIndex !== 0" src="../assets/mask@2x.png" alt />
              <p>1、提交询价采购信息</p>
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/page/page2@2x.png" alt />
              <img class="mask" v-if="activeIndex !== 1" src="../assets/mask@2x.png" alt />
              <p>2、客服联系匹配供应商确定价格</p>
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/page/page3@2x.png" alt />
              <img class="mask" v-if="activeIndex !== 2" src="../assets/mask@2x.png" alt />
              <p>3、完成采购</p>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="us" id="div5">
        <div class="main">
          <img class="bg" src="../assets/about_bg_image@2x.png" alt />
          <div class="top-div">
            <p>关于·我们</p>
            <p class="little-p">ABOUT OUR COMPANY</p>
            <p class="d-p">
              <a class="a1"></a>
              <a class="a2"></a>
            </p>
          </div>
          <div
            class="bottom-div"
          >新视采创立于2020年，旨在帮助用户一站直达全网商品信息，触达海量优质商家。满足用户对于采购信息检索的需求，同时为入驻企业找到精准买家。新视采用帮助中小企业更好地解决采购批发渠道、货源问题。</div>
        </div>
      </div>
      <div class="yd">
        <div class="main">
          <el-row :gutter="20">
            <el-col :span="8">
              <div class="item">
                <div class="left">
                  <img src="../assets/tenet_icon_zhuan@2x.png" alt />
                </div>
                <div class="right">
                  <p class="p1">专注</p>
                  <p class="p2">全网提供零件买卖货源信息</p>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="item">
                <div class="left">
                  <img src="../assets/tenet_icon_cheng@2x.png" alt />
                </div>
                <div class="right">
                  <p class="p1">诚信</p>
                  <p class="p2">多角度从采购人的切身利益考虑</p>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="item">
                <div class="left">
                  <img src="../assets/tenet_icon_zun@2x.png" alt />
                </div>
                <div class="right">
                  <p class="p1">低价</p>
                  <p class="p2">新的采购方式，提供优质服务</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="lxfs">
        <div class="main">
          <p class="msg">
            <img src="../assets/footer_icon_iphone@2x.png" alt /> 手机： 182-5569-8813
          </p>
          <p class="msg">
            <img src="../assets/footer_icon_email@2x.png" alt /> 邮箱：xinshicai@sina.com
          </p>
          <p class="msg">
            <img src="../assets/footer_icon_ address@2x.png" alt /> 地址：安徽省合肥市徽州大道8900号
          </p>
          <div class="right1">
            <img class="code" src="../assets/qrcode.png" alt />
            <p>
              <img src="../assets/sys.svg" alt /> 扫码关注微信公众号
            </p>
          </div>
          <div class="right2">
            <img class="code" src="../assets/xcx.jpg" alt />
            <p>
              <img src="../assets/sys.svg" alt /> 扫码体验微信小程序
            </p>
          </div>
        </div>
      </div>
      <div class="bottom">Copyright © 京ICP证030173号 京公网安备1100000200000</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      imgHeight: '600px',
      activeName: 'second',
      activeIndex: 0,
      activeNavIndex: 0
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.handleWidth()
      })()
    }
    this.handleWidth()
  },
  methods: {
    handleWidth() {
      this.imgHeight = (document.body.clientWidth * 670) / 1920 + 'px'
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    changeTab(e) {
      console.log(e)
      this.activeIndex = e
    },
    scrollIntoView(id) {
      // 等同于element.scrollIntoView(true)
      console.log(id)
      const element = document.getElementById(id)
      element.scrollIntoView(true)

      // Boolean型参数
      // true  元素的顶端将和其所在滚动区的可视区域的顶端对齐 ； false 元素的底端将和其所在滚动区的可视区域的底端对齐
      // element.scrollIntoView();

      // 一个带有选项的object：
      // {
      //     behavior: "auto"(默认)  | "instant" | "smooth"(缓动),
      //     block:    "start" | "end",
      // }
      // element.scrollIntoView(); // Object型参数
    }
  }
}
</script>
<style lang="stylus">
.main-box {
  height: 100vw;
  overflow: hidden;
}

.main-box .xj .el-carousel__item p {
  color: #07080D;
  font-size: 18px;
  margin-top: 40px;
  font-weight: 400;
}

.main-box .tip-box {
  display: inline-block;
  margin: 0;
}

.main-box .el-popover {
  width: 242px;
  box-sizing: border-box;
}

.main-box .el-popover--plain {
  padding: 10px;
  box-sizing: border-box;
}

.main-box .xj .el-carousel__item {
  background: transparent;
  text-align: center;
}

.main-box .xj .el-carousel__item img {
  width: 50%;
  box-shadow: 0px 9px 13px 0px rgba(182, 182, 182, 0.21);
  border-radius: 30px;
}

.main-box .xj .el-carousel__item .mask {
  position: absolute;
  top: 2px;
  left: 25%;
}
</style>
<style lang="stylus" scoped>
.d-p {
  line-height: 1px;
  padding: 0;
  margin: 0;

  a {
    display: inline-block;
  }

  .a1 {
    width: 91px;
    height: 2px;
    background: rgba(218, 41, 34, 1);
  }

  .a2 {
    width: 91px;
    height: 2px;
    background: rgba(157, 157, 157, 1);
    margin-left: 13px;
  }
}

.main-box {
  background-color: #f9f9f9;

  .bottom {
    height: 50px;
    background: rgba(249, 249, 249, 1);
    border-radius: 2px;
    line-height: 50px;
    font-size: 12px;
    color: #666666;
    font-weight: 400;
    text-align: center;
  }

  .lxfs {
    height: 280px;
    box-sizing: border-box;
    background: rgba(46, 46, 46, 1);
    border-radius: 2px;
    padding: 72px 0 37px;

    .main {
      max-width: 1200px;
      margin: 0 auto;
      position: relative;

      .right1 {
        position: absolute;
        text-align: center;
        right: 180px;
        top: -15px;

        .code {
          width: 140px;
          height: 140px;
        }

        p {
          font-size: 12px;
          color: #999999;
          font-weight: 400;
          margin-top: 10px;

          img {
            width: 12px;
            height: 12px;
            position: relative;
            top: 3px;
          }
        }
      }

      .right2 {
        position: absolute;
        right: 0;
        top: -15px;
        text-align: center;

        .code {
          width: 140px;
          height: 140px;
        }

        p {
          font-size: 12px;
          color: #999999;
          font-weight: 400;
          margin-top: 10px;

          img {
            width: 12px;
            height: 12px;
            position: relative;
            top: 3px;
          }
        }
      }

      .msg {
        font-size: 15px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 35px;

        img {
          position: relative;
          top: 3px;
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .yd {
    background: white;

    .main {
      max-width: 1200px;
      margin: 0 auto;
      padding: 150px 0 105px;

      .item {
        display: flex;
        align-items: center;

        .left {
          width: 130px;
          flex-grow: 0;

          img {
            width: 106px;
            height: 106px;
          }
        }

        .right {
          flex-grow: 1;

          .p1 {
            font-size: 28px;
            color: #333333;
            font-weight: 600;
            margin-bottom: 11px;
          }

          .p2 {
            font-size: 14px;
            color: #666666;
            font-weight: 400;
          }
        }
      }
    }
  }

  .us {
    .main {
      max-width: 1200px;
      margin: 0 auto;
      z-index: 2;
      position: relative;

      .bg {
        height: 460px;
        width: 100%;
      }

      .top-div {
        position: absolute;
        width: 215px;
        top: 80px;
        left: 0;
        font-size: 46px;
        color: #07080D;
        font-weight: 500;

        .little-p {
          color: #07080D;
          font-size: 16px;
          font-weight: 400;
        }
      }

      .bottom-div {
        position: absolute;
        width: 455px;
        bottom: 130px;
        left: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #999999;
      }
    }
  }

  .xj {
    padding: 80px 40px;

    .title-p1 {
      color: #333333;
      font-size: 32px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 16px;
    }

    .title-p2 {
      color: #999999;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 60px;
    }

    .main {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  .lc {
    background: url('../assets/flow_bg_image@2x.png') no-repeat;
    background-size: 100% 100%;

    .main {
      max-width: 1200px;
      margin: 0 auto;
      z-index: 2;
      position: relative;
      padding-bottom: 80px;

      .top-div {
        width: 215px;
        padding-top: 80px;
        font-size: 46px;
        color: #fff;
        font-weight: 500;
        margin-bottom: 80px;

        .little-p {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  .ln {
    background: white;
    position: relative;

    .imgs {
      display: flex;
      margin: 115px auto 0;
      padding-bottom: 115px;
      max-width: 1200px;

      .kf {
        position: absolute;
        right: 20px;
        bottom: 115px;
        width: 230px;
        height: 259px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 9px 13px 0px rgba(182, 182, 182, 0.21);
        border-radius: 4px;
        border: 1px solid rgba(245, 245, 245, 1);
        text-align: center;

        .p1 {
          font-size: 20px;
          color: #07080D;
          font-weight: 500;
          margin-top: 20px;
        }

        .p2 {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          margin: 15px 0;

          img {
            width: 14px;
            position: relative;
            top: 4px;
          }
        }

        .p3 {
          img {
            width: 140px;
          }
        }
      }

      .item {
        width: 290px;
        margin-right: 10px;

        p {
          width: 170px;
          height: 51px;
          border-radius: 1px;
          border: 1px solid rgba(232, 232, 232, 1);
          color: #666666;
          font-size: 18px;
          font-weight: 500;
          line-height: 51px;
          text-align: center;
          margin: 0 auto;
        }

        img {
          width: 290px;
          height: 290px;
        }
      }
    }

    .bg {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }

    .main {
      // max-width: 1200px;
      margin: 0 auto;
      z-index: 2;
      position: relative;
      height: 350px;

      .top-div {
        width: 100%;
        background: #F6F6F6;
        padding: 80px 0 40px 0;
        margin: auto;
        font-size: 46px;
        color: #07080D;
        font-weight: 500;

        &>div {
          max-width: 1200px;
          margin: auto;
        }

        .little-p {
          color: #07080D;
          font-size: 16px;
          font-weight: 400;
          max-width: 1200px;
          margin: auto;
        }
      }
      .bottom-box {
        max-width: 1200px;
        margin: auto;
      }
      .bottom-div {
        width: 512px;
        margin-top: 40px;
        font-weight: 400;
        font-size: 16px;
        color: #999999;
        line-height: 22px;
      }
    }
  }

  .buy {
    background: #fff;
    padding: 80px 0 130px;

    .title-p1 {
      color: #333333;
      font-size: 32px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 16px;
    }

    .title-p2 {
      color: #999999;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 60px;
    }

    .main {
      display: flex;
      max-width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      justify-content: space-between;

      .div1 {
        width: 200px;
        text-align: center;

        .p1 {
          font-size: 22px;
          font-weight: 500;
          color: #07080D;
        }

        .p2 {
          font-weight: 400;
          color: #666666;
          font-size: 14px;
          margin-top: 16px;
        }

        .img1 {
          width: 146px;
        }

        .img2 {
          width: 36px;
          position: relative;
          top: 4px;
        }
      }

      .div2 {
        width: 20px;
        text-align: center;

        img {
          width: 20px;
          margin-top: 60px;
        }
      }
    }
  }

  .content {
    scroll-behavior: smooth;
    overflow: auto;
    position: absolute;
    top: 100px;
    width: 100%;
    bottom: 0;
  }

  .nav {
    background: #fff;
    height: 70px;
    line-height: 70px;
    width: 100%;

    .main {
      max-width: 1200px;
      background: #fff;
      margin: 0 auto;
      position: relative;

      img {
        width: 90px;
        position: absolute;
        top: 16px;
        left: 0;
        display: inline;
      }

      .nav-tab {
        position: absolute;
        right: 0;
        top: 0;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: inline;
          cursor: pointer;

          span {
            display: inline-block;
            height: 64px;
            line-height: 64px;
            text-decoration: none;
            margin-left: 70px;
            font-size: 16px;
            color: #07080D;
            font-weight: 400;

            &.active {
              color: #DA2922;
              border-bottom: 2px solid #DA2922;
            }
          }
        }
      }
    }
  }

  .header {
    width: 100%;
    height: 30px;
    line-height: 30px;
    color: #999999;
    background: #f9f9f9;
    font-size: 12px;

    &-content {
      max-width: 1200px;
      margin: auto;
    }

    span {
      margin: 0 25px;

      img {
        width: 18px;
        position: relative;
        top: 5px;
      }
    }
  }
}
</style>